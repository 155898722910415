import { Box, BoxProps } from '@chakra-ui/react';

export const HeadingCircuit = ({
  wrapperProps,
}: {
  wrapperProps?: BoxProps;
}) => (
  <Box h="full" w="full" {...wrapperProps}>
    <svg
      width="100%"
      height="21"
      viewBox="0 0 599 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M585.37 19.4477L531.303 19.4477C525.179 19.4535 519.302 17.0965 514.957 12.8922L509.39 7.5261C505.034 3.31754 499.135 1.22686 493 1.21678"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.25"
        d="M592.843 19.8255L592.843 19.0713L593.587 19.0713L593.587 19.8255L592.843 19.8255Z"
        fill="currentColor"
      />
      <path
        opacity="0.25"
        d="M594.332 19.8255L594.332 19.0713L595.076 19.0713L595.076 19.8255L594.332 19.8255Z"
        fill="currentColor"
      />
      <path
        opacity="0.5"
        d="M595.82 19.8255L595.82 19.0713L596.564 19.0713L596.564 19.8255L595.82 19.8255Z"
        fill="currentColor"
      />
      <path
        d="M586.888 19.8255L586.888 19.0713L587.632 19.0713L587.632 19.8255L586.888 19.8255Z"
        fill="currentColor"
      />
      <path
        d="M585.013 19.8255L585.013 19.0713L585.757 19.0713L585.757 19.8255L585.013 19.8255Z"
        fill="currentColor"
      />
      <path
        d="M597.666 19.8255L597.666 19.0713L598.41 19.0713L598.41 19.8255L597.666 19.8255Z"
        fill="currentColor"
      />
      <path
        opacity="0.5"
        d="M588.377 19.8255L588.377 19.0713L589.121 19.0713L589.121 19.8255L588.377 19.8255Z"
        fill="currentColor"
      />
      <path
        opacity="0.25"
        d="M589.866 19.8255L589.866 19.0713L590.61 19.0713L590.61 19.8255L589.866 19.8255Z"
        fill="currentColor"
      />
      <path
        opacity="0.25"
        d="M591.354 19.8255L591.354 19.0713L592.098 19.0713L592.098 19.8255L591.354 19.8255Z"
        fill="currentColor"
      />
      <path
        opacity="0.25"
        d="M529.934 0.217026L529.934 3.11768L526.956 3.11768L526.956 0.217026L529.934 0.217026Z"
        fill="currentColor"
      />
      <path
        opacity="0.5"
        d="M527.313 0.970848L527.313 0.59375L527.701 0.59375L527.701 0.970848L527.313 0.970848Z"
        fill="currentColor"
      />
      <path
        d="M525.467 2.42105L525.467 2.04395L525.854 2.04395L525.854 2.42105L525.467 2.42105Z"
        fill="currentColor"
      />
      <path
        d="M525.467 1.319L525.467 0.941895L525.854 0.941895L525.854 1.319L525.467 1.319Z"
        fill="currentColor"
      />
      <path
        d="M519.87 2.42105L519.87 2.04395L520.257 2.04395L520.257 2.42105L519.87 2.42105Z"
        fill="currentColor"
      />
      <path
        d="M519.87 1.319L519.87 0.941895L520.257 0.941895L520.257 1.319L519.87 1.319Z"
        fill="currentColor"
      />
      <path
        d="M514.303 2.42105L514.303 2.04395L514.69 2.04395L514.69 2.42105L514.303 2.42105Z"
        fill="currentColor"
      />
      <path
        d="M514.303 1.319L514.303 0.941895L514.69 0.941895L514.69 1.319L514.303 1.319Z"
        fill="currentColor"
      />
      <path
        opacity="0.5"
        d="M529.189 2.76968L529.189 2.39258L529.576 2.39258L529.576 2.76968L529.189 2.76968Z"
        fill="currentColor"
      />
      <path
        opacity="0.25"
        d="M527.7 2.42117L527.7 0.59375L529.576 0.59375L529.576 2.42117L527.7 2.42117Z"
        fill="currentColor"
      />
      <path
        opacity="0.25"
        d="M527.313 2.76932L527.313 0.941895L529.189 0.941895L529.189 2.76932L527.313 2.76932Z"
        fill="currentColor"
      />
      <path
        opacity="0.25"
        d="M524.336 0.217026L524.336 3.11768L521.359 3.11768L521.359 0.217026L524.336 0.217026Z"
        fill="currentColor"
      />
      <path
        opacity="0.5"
        d="M521.746 0.970848L521.746 0.59375L522.133 0.59375L522.133 0.970848L521.746 0.970848Z"
        fill="currentColor"
      />
      <path
        opacity="0.5"
        d="M523.592 2.76968L523.592 2.39258L523.979 2.39258L523.979 2.76968L523.592 2.76968Z"
        fill="currentColor"
      />
      <path
        opacity="0.25"
        d="M522.103 2.42117L522.103 0.59375L523.979 0.59375L523.979 2.42117L522.103 2.42117Z"
        fill="currentColor"
      />
      <path
        opacity="0.25"
        d="M521.746 2.76932L521.746 0.941895L523.622 0.941895L523.622 2.76932L521.746 2.76932Z"
        fill="currentColor"
      />
      <path
        opacity="0.25"
        d="M518.769 0.217026L518.769 3.11768L515.791 3.11768L515.791 0.217026L518.769 0.217026Z"
        fill="currentColor"
      />
      <path
        opacity="0.5"
        d="M516.148 0.970848L516.148 0.59375L516.535 0.59375L516.535 0.970848L516.148 0.970848Z"
        fill="currentColor"
      />
      <path
        opacity="0.5"
        d="M518.024 2.76968L518.024 2.39258L518.411 2.39258L518.411 2.76968L518.024 2.76968Z"
        fill="currentColor"
      />
      <path
        opacity="0.25"
        d="M516.536 2.42117L516.536 0.59375L518.411 0.59375L518.411 2.42117L516.536 2.42117Z"
        fill="currentColor"
      />
      <path
        opacity="0.25"
        d="M516.148 2.76932L516.148 0.941895L518.024 0.941895L518.024 2.76932L516.148 2.76932Z"
        fill="currentColor"
      />
      <path
        opacity="0.25"
        d="M513.171 0.217026L513.171 3.11768L510.194 3.11768L510.194 0.217026L513.171 0.217026Z"
        fill="currentColor"
      />
      <path
        opacity="0.5"
        d="M510.581 0.970848L510.581 0.59375L510.968 0.59375L510.968 0.970848L510.581 0.970848Z"
        fill="currentColor"
      />
      <path
        opacity="0.5"
        d="M512.427 2.76968L512.427 2.39258L512.814 2.39258L512.814 2.76968L512.427 2.76968Z"
        fill="currentColor"
      />
      <path
        opacity="0.25"
        d="M510.938 2.42117L510.938 0.59375L512.814 0.59375L512.814 2.42117L510.938 2.42117Z"
        fill="currentColor"
      />
      <path
        opacity="0.25"
        d="M510.581 2.76932L510.581 0.941895L512.457 0.941895L512.457 2.76932L510.581 2.76932Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M491 0.716797L490 0.716797V1.7168H491V0.716797Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M483 0.716797L482 0.716797V1.7168H483V0.716797Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M489 0.716797L488 0.716797V1.7168H489V0.716797Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M485 0.716797L484 0.716797V1.7168H485V0.716797Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M487 0.716797L486 0.716797V1.7168H487V0.716797Z"
        fill="currentColor"
      />
      <path
        opacity="0.2"
        d="M480 1.2168L-1.09642e-05 1.2168"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
    </svg>
  </Box>
);
