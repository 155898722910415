/* eslint-disable import/no-extraneous-dependencies */
import { Box, BoxProps, HStack, VStack } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import {
  getImage,
  StaticImage,
  ImageDataLike,
  IGatsbyImageData,
  GatsbyImage,
} from 'gatsby-plugin-image';
import React from 'react';
import {
  Heading,
  IconWrapper,
  Link,
  Subheading,
  Text,
  Title,
  hexToRGB,
} from 'ui';
import { HeadingCircuit } from '../assets/svgs/HeadingCircuit';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { getPseudoBorderBottomProps } from '../utils/getPseudoBorderBottomProps';

// background vector
const eso1bgImg = `../assets/svgs/eso1bg.svg`;

// investors
const konvoyImg = `../assets/images/team/investors/konvoy.png`;
const panteraImg = `../assets/images/team/investors/pantera.png`;
const oldFashionImg = `../assets/images/team/investors/old-fashion.png`;
const alamedaImg = `../assets/images/team/investors/alameda.png`;
const solanaCapitalImg = `../assets/images/team/investors/solana-capital.png`;
const mechanismImg = `../assets/images/team/investors/mechanism.png`;
const xooglerImg = `../assets/images/team/investors/xoogler.png`;
const collabImg = `../assets/images/team/investors/collab.png`;
const spartanImg = `../assets/images/team/investors/spartan.png`;
const animocaImg = `../assets/images/team/investors/animoca.png`;
const olivexImg = `../assets/images/team/investors/olivex.png`;
const cmtImg = `../assets/images/team/investors/cmt.png`;
const defiImg = `../assets/images/team/investors/defi.png`;
const infinityImg = `../assets/images/team/investors/infinity.png`;
const eniacImg = `../assets/images/team/investors/eniac.png`;
const cmsImg = `../assets/images/team/investors/cms.png`;
const gbvImg = `../assets/images/team/investors/gbv.png`;
const yieldImg = `../assets/images/team/investors/yield.png`;
const meritImg = `../assets/images/team/investors/merit.png`;
const bitScaleImg = `../assets/images/team/investors/bit-scale.png`;
const evernewImg = `../assets/images/team/investors/evernew.png`;

type TeamMemberProps = {
  image?: { localFile: ImageDataLike };
  firstName?: string;
  lastName: string;
  department?: {
    name: string;
  };
  createdAt?: string;
  isCofounder?: boolean;
  jobTitle: string;
  favoriteGame?: string;
  linkedin?: string;
  twitter?: string;
};

type TeamMemberCardProps = TeamMemberProps & {
  profileImage?: IGatsbyImageData;
  twitter?: string;
  linkedin?: string;
  primaryColor?: string;
  secondaryColor?: string;
  bgGradient?: string;
  detailsWrapperProps?: BoxProps;
};

const SectionHeading = ({
  header,
  headerColor,
  wrapperProps,
}: {
  header: string;
  headerColor: string;
  wrapperProps: BoxProps;
}) => (
  <HStack h="full" w="full" align="flex-start">
    <Heading as="h2" size="lg" color={headerColor}>
      {header}
    </Heading>
    <Box display={{ base: `none`, lg: `block` }}>
      <HeadingCircuit wrapperProps={{ ...wrapperProps }} />
    </Box>
  </HStack>
);

const TeamMemberCard = ({
  profileImage,
  firstName,
  lastName,
  isCofounder,
  jobTitle,
  favoriteGame,
  twitter,
  linkedin,
  primaryColor = `#00FFC8`,
  secondaryColor = `#BDFFF1`,
  bgGradient = `linear-gradient(308.45deg, rgba(0, 255, 200, 0.4) -94.97%, rgba(0, 255, 200, 0) 70.06%)`,
  detailsWrapperProps,
}: TeamMemberCardProps) => {
  const [red, green, blue] = hexToRGB(primaryColor);

  return (
    <VStack
      align="flex-start"
      background={bgGradient}
      ml="8px"
      maxWidth={{ base: `175px`, sm: `148px` }}
      style={{ marginBottom: `8px` }}
    >
      <Box w="full">
        <GatsbyImage
          image={profileImage!}
          alt={`image of ${firstName} ${lastName}`}
          objectPosition="center center"
          objectFit="contain"
        />
      </Box>
      <Box
        border={`1px solid rgba(${red}, ${green}, ${blue}, 0.1)`}
        w="full"
        h="full"
        minH="195px"
        p="8px 0px 20px 8px"
        {...detailsWrapperProps}
      >
        <Box>
          {firstName && (
            <Subheading as="h5" size="2xs" color={secondaryColor}>
              {firstName}
            </Subheading>
          )}
          <Subheading
            as="h4"
            size="md"
            color={primaryColor}
            mt="-3px"
            whiteSpace="normal"
          >
            {lastName}
          </Subheading>
          {isCofounder && (
            <Text color={secondaryColor} mb="-8px">
              Co-Founder
            </Text>
          )}
          <Text
            color={secondaryColor}
            mt={isCofounder ? `unset` : `6px`}
            style={{ lineHeight: 1 }}
          >
            {jobTitle}
          </Text>
        </Box>
        {favoriteGame && (
          <Box mt="8px">
            <Subheading as="h5" size="3xs" color={primaryColor}>
              LOVES
            </Subheading>

            <Text color={secondaryColor} style={{ lineHeight: 1 }}>
              {favoriteGame}
            </Text>
          </Box>
        )}
        {(twitter || linkedin) && (
          <Box mt="8px">
            {twitter && (
              <HStack justify="flex-start">
                <IconWrapper
                  color={primaryColor}
                  iconName="twitter"
                  iconSize={16}
                />
                <Link
                  href={twitter}
                  target="_blank"
                  color={secondaryColor}
                  size="sm"
                  style={{ marginInlineStart: `5px` }}
                  {...getPseudoBorderBottomProps(primaryColor, secondaryColor)}
                >
                  Twitter
                </Link>
              </HStack>
            )}
            {linkedin && (
              <HStack justify="flex-start">
                <IconWrapper
                  color={primaryColor}
                  iconName="linkedin"
                  iconSize={16}
                />
                <Link
                  href={linkedin}
                  target="_blank"
                  color={secondaryColor}
                  size="sm"
                  style={{ marginInlineStart: `5px` }}
                  {...getPseudoBorderBottomProps(primaryColor, secondaryColor)}
                >
                  Linkedin
                </Link>
              </HStack>
            )}
          </Box>
        )}
      </Box>
    </VStack>
  );
};

const Investors = () => (
  <VStack flexWrap="wrap" w="full">
    <HStack w="full" justify="space-between">
      <StaticImage
        placeholder="blurred"
        src={konvoyImg}
        quality={100}
        alt="konvoy"
      />
      <StaticImage
        placeholder="blurred"
        src={panteraImg}
        quality={100}
        alt="pantera"
      />
    </HStack>
    <HStack w="full" justify="space-between">
      <StaticImage
        placeholder="blurred"
        src={oldFashionImg}
        quality={100}
        alt="old-fashion"
      />
      <StaticImage
        placeholder="blurred"
        src={alamedaImg}
        quality={100}
        alt="alameda"
      />
      <StaticImage
        placeholder="blurred"
        src={solanaCapitalImg}
        quality={100}
        alt="solana-capital"
      />
    </HStack>
    <HStack w="full" justify="space-between">
      <StaticImage
        placeholder="blurred"
        src={mechanismImg}
        quality={100}
        alt="mechanism"
      />
      <StaticImage
        placeholder="blurred"
        src={xooglerImg}
        quality={100}
        alt="xoogler"
      />
      <StaticImage
        placeholder="blurred"
        src={collabImg}
        quality={100}
        alt="collab"
      />
    </HStack>
    <HStack w="full" justify="space-between">
      <StaticImage
        placeholder="blurred"
        src={spartanImg}
        quality={100}
        alt="spartan"
      />
      <StaticImage
        placeholder="blurred"
        src={animocaImg}
        quality={100}
        alt="animoca"
      />
      <StaticImage
        placeholder="blurred"
        src={olivexImg}
        quality={100}
        alt="olivex"
      />
    </HStack>
    <HStack w="full" justify="space-between">
      <StaticImage placeholder="blurred" src={cmtImg} quality={100} alt="cmt" />
      <StaticImage
        placeholder="blurred"
        src={defiImg}
        quality={100}
        alt="defi"
      />
      <StaticImage
        placeholder="blurred"
        src={infinityImg}
        quality={100}
        alt="infinity"
      />
    </HStack>
    <HStack w="full" justify="space-between">
      <StaticImage
        placeholder="blurred"
        src={eniacImg}
        quality={100}
        alt="eniac"
      />
      <StaticImage placeholder="blurred" src={cmsImg} quality={100} alt="cms" />
      <StaticImage placeholder="blurred" src={gbvImg} quality={100} alt="gbv" />
    </HStack>
    <HStack w="full" justify="space-between">
      <StaticImage
        placeholder="blurred"
        src={yieldImg}
        quality={100}
        alt="yield"
      />
      <StaticImage
        placeholder="blurred"
        src={meritImg}
        quality={100}
        alt="merit"
      />
      <StaticImage
        placeholder="blurred"
        src={bitScaleImg}
        quality={100}
        alt="bit-scale"
      />
    </HStack>
    <HStack w="full" justify="center" pb="20px">
      <StaticImage
        placeholder="blurred"
        src={evernewImg}
        quality={100}
        alt="evernew"
      />
    </HStack>
  </VStack>
);

const Team = () => {
  const { strapiLandingTeam: strapiLT, allStrapiTeamMember } = useStaticQuery(
    graphql`
      query {
        strapiLandingTeam {
          pageTitle
          pageDescription
          title
          paragraph1
          paragraph2
          paragraph3
        }
        allStrapiTeamMember {
          nodes {
            createdAt
            firstName
            lastName
            jobTitle
            favoriteGame
            isCofounder
            linkedin
            twitter
            department {
              name
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, quality: 100)
                }
              }
            }
          }
        }
      }
    `,
  );

  const teamMembers = allStrapiTeamMember.nodes.sort(
    (a: TeamMemberProps, b: TeamMemberProps) =>
      // sort from oldest to newest
      new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime(),
  );
  const executivesTeam = teamMembers.filter(
    (member: TeamMemberProps) => member.department?.name === `Executive`,
  );
  const engineeringTeam = teamMembers.filter(
    (member: TeamMemberProps) => member.department?.name === `Engineering`,
  );
  const productTeam = teamMembers.filter(
    (member: TeamMemberProps) => member.department?.name === `Product`,
  );
  const comAndMarketingTeam = teamMembers.filter(
    (member: TeamMemberProps) =>
      member.department?.name === `Community & Marketing`,
  );
  const operationsTeam = teamMembers.filter(
    (member: TeamMemberProps) => member.department?.name === `Operations`,
  );
  const advisoryTeam = teamMembers.filter(
    (member: TeamMemberProps) => member.department?.name === `Advisors`,
  );
  const celebrityHolders = teamMembers.filter(
    (member: TeamMemberProps) =>
      member.department?.name === `Celebrity Holders`,
  );

  return (
    <Layout>
      <SEO title={strapiLT.pageTitle} description={strapiLT.pageDescription} />
      <VStack
        overflowX="hidden"
        w="full"
        h="full"
        pos="relative"
        paddingTop="0"
        px="16px"
        mt={{ base: `20px`, lg: `unset` }}
      >
        <VStack
          w="full"
          pos="absolute"
          top="-10%"
          opacity={0.3}
          transform="rotate(90deg)"
        >
          <StaticImage
            placeholder="blurred"
            src={eso1bgImg}
            quality={100}
            alt="eso1bg-img"
          />
        </VStack>
        <VStack
          w="full"
          h="full"
          maxW="820px"
          zIndex={1}
          gap={4}
          pb={{ base: `200px` }}
        >
          <VStack w="full" h="auto" mt="60px" id="about-us">
            <Title as="h1" color="#00FFC8" w="full">
              {strapiLT.title}
            </Title>
            <Text color="#BDFFF1">{strapiLT.paragraph1}</Text>
            <Text color="#BDFFF1">{strapiLT.paragraph2}</Text>
            <Text color="#BDFFF1">
              {strapiLT.paragraph3}
              {` `}
              <Link
                href="https://jobs.lever.co/genopets"
                target="_blank"
                color="#BDFFF1"
                size="sm"
                {...getPseudoBorderBottomProps(`#00FFC8`, `#BDFFF1`)}
              >
                open positions
              </Link>
              .
            </Text>
          </VStack>
          <VStack align="flex-start" w="full" id="executives">
            <SectionHeading
              header="executives"
              headerColor="#00FFC8"
              wrapperProps={{ color: `#00FFC8` }}
            />
            <HStack
              flexWrap="wrap"
              justify="center"
              pr={{ base: `8px`, md: `0px` }}
            >
              {executivesTeam.map((exec: TeamMemberProps) => {
                const image = getImage(exec.image!.localFile);
                return (
                  <TeamMemberCard
                    profileImage={image}
                    firstName={exec.firstName || ``}
                    lastName={exec.lastName}
                    isCofounder={exec.isCofounder || false}
                    jobTitle={exec.jobTitle}
                    favoriteGame={exec.favoriteGame || ``}
                    twitter={exec.twitter || ``}
                    linkedin={exec.linkedin || ``}
                    detailsWrapperProps={{
                      maxW: { base: `175px`, sm: `148px` },
                      style: { marginTop: `0px` },
                    }}
                  />
                );
              })}
            </HStack>
          </VStack>
          <VStack align="flex-start" w="full" id="engineering">
            <SectionHeading
              header="engineering"
              headerColor="#FF7A00"
              wrapperProps={{ color: `#FF7A00` }}
            />
            <HStack
              flexWrap="wrap"
              justify={{ base: `center`, lg: `flex-start` }}
              pr={{ base: `8px`, md: `0px` }}
            >
              {engineeringTeam.map((engineer: TeamMemberProps) => {
                const image = getImage(engineer.image!.localFile);
                return (
                  <TeamMemberCard
                    profileImage={image}
                    firstName={engineer.firstName || ``}
                    lastName={engineer.lastName}
                    isCofounder={engineer.isCofounder || false}
                    jobTitle={engineer.jobTitle}
                    favoriteGame={engineer.favoriteGame || ``}
                    twitter={engineer.twitter || ``}
                    linkedin={engineer.linkedin || ``}
                    primaryColor="#FF7A00"
                    secondaryColor="#FFD9B6"
                    bgGradient="linear-gradient(308.45deg, rgba(255, 122, 0, 0.4) -94.97%, rgba(255, 122, 0, 0) 70.06%)"
                    detailsWrapperProps={{
                      maxW: `185px`,
                      maxH: `161px`,
                      style: { marginTop: `-2px` },
                    }}
                  />
                );
              })}
            </HStack>
          </VStack>
          <VStack align="flex-start" w="full" id="product">
            <SectionHeading
              header="product"
              headerColor="#FFC000"
              wrapperProps={{ color: `#FFC000` }}
            />
            <HStack
              flexWrap="wrap"
              justify={{ base: `center`, lg: `flex-start` }}
              pr={{ base: `8px`, md: `0px` }}
            >
              {productTeam.map((member: TeamMemberProps) => {
                const image = getImage(member.image!.localFile);
                return (
                  <TeamMemberCard
                    profileImage={image}
                    firstName={member.firstName || ``}
                    lastName={member.lastName}
                    isCofounder={member.isCofounder || false}
                    jobTitle={member.jobTitle}
                    favoriteGame={member.favoriteGame || ``}
                    twitter={member.twitter || ``}
                    linkedin={member.linkedin || ``}
                    primaryColor="#FFC000"
                    secondaryColor="#FFECB3"
                    bgGradient="linear-gradient(308.45deg, rgba(255, 192, 0, 0.4) -94.97%, rgba(255, 192, 0, 0.01) 70.06%)"
                    detailsWrapperProps={{
                      maxW: `185px`,
                      style: { marginTop: `-2px` },
                    }}
                  />
                );
              })}
            </HStack>
          </VStack>
          <VStack align="flex-start" w="full" id="community">
            <SectionHeading
              header="community & marketing"
              headerColor="#FF0063"
              wrapperProps={{ color: `#FF0063`, maxW: `374px` }}
            />
            <HStack
              flexWrap="wrap"
              justify={{ base: `center`, lg: `flex-start` }}
              pr={{ base: `8px`, md: `0px` }}
            >
              {comAndMarketingTeam.map((member: TeamMemberProps) => {
                const image = getImage(member.image!.localFile);
                return (
                  <TeamMemberCard
                    profileImage={image}
                    firstName={member.firstName || ``}
                    lastName={member.lastName}
                    isCofounder={member.isCofounder || false}
                    jobTitle={member.jobTitle}
                    favoriteGame={member.favoriteGame || ``}
                    twitter={member.twitter || ``}
                    linkedin={member.linkedin || ``}
                    primaryColor="#FF0063"
                    secondaryColor="#FF96BF"
                    bgGradient="linear-gradient(308.45deg, rgba(255, 0, 99, 0.4) -94.97%, rgba(255, 0, 99, 0) 70.06%)"
                    detailsWrapperProps={{
                      maxW: `185px`,
                      style: { marginTop: `-2px` },
                    }}
                  />
                );
              })}
            </HStack>
          </VStack>
          <VStack
            align={{ base: `center`, md: `flex-start` }}
            w="full"
            id="administrative"
          >
            <SectionHeading
              header="operations"
              headerColor="#B700FF"
              wrapperProps={{ color: `#B700FF` }}
            />
            <HStack
              flexWrap="wrap"
              justify={{ base: `center`, lg: `flex-start` }}
              pr={{ base: `8px`, md: `0px` }}
            >
              {operationsTeam.map((member: TeamMemberProps) => {
                const image = getImage(member.image!.localFile);
                return (
                  <TeamMemberCard
                    profileImage={image}
                    firstName={member.firstName || ``}
                    lastName={member.lastName}
                    isCofounder={member.isCofounder || false}
                    jobTitle={member.jobTitle}
                    favoriteGame={member.favoriteGame || ``}
                    twitter={member.twitter || ``}
                    linkedin={member.linkedin || ``}
                    primaryColor="#B700FF"
                    secondaryColor="#EDBFFF"
                    bgGradient="linear-gradient(308.45deg, rgba(183, 0, 255, 0.4) -94.97%, rgba(183, 0, 255, 0) 70.06%)"
                    detailsWrapperProps={{
                      maxW: `185px`,
                      style: { marginTop: `-2px` },
                    }}
                  />
                );
              })}
            </HStack>
          </VStack>
          <VStack align="flex-start" w="full" id="advisors">
            <SectionHeading
              header="advisors"
              headerColor="#00FFC8"
              wrapperProps={{ color: `#00FFC8` }}
            />
            <HStack
              flexWrap="wrap"
              justify={{ base: `center`, lg: `flex-start` }}
              pr={{ base: `8px`, md: `0px` }}
            >
              {advisoryTeam.map((member: TeamMemberProps) => {
                const image = getImage(member.image!.localFile);
                return (
                  <TeamMemberCard
                    profileImage={image}
                    firstName={member.firstName || ``}
                    lastName={member.lastName}
                    isCofounder={member.isCofounder || false}
                    jobTitle={member.jobTitle}
                    favoriteGame={member.favoriteGame || ``}
                    twitter={member.twitter || ``}
                    linkedin={member.linkedin || ``}
                    primaryColor="#00FFC8"
                    secondaryColor="#BDFFF1"
                    bgGradient="linear-gradient(308.45deg, rgba(0, 255, 200, 0.4) -94.97%, rgba(0, 255, 200, 0) 70.06%)"
                    detailsWrapperProps={{
                      maxW: `248px`,
                      minH: `140px`,
                      style: { marginTop: `-2px` },
                    }}
                  />
                );
              })}
            </HStack>
          </VStack>
          <VStack align="flex-start" w="full" id="genopets-holders">
            <SectionHeading
              header="1 of 1 genopets holders"
              headerColor="#00FFC8"
              wrapperProps={{ color: `#00FFC8`, maxW: `350px` }}
            />
            <HStack
              flexWrap="wrap"
              justify={{ base: `center`, lg: `flex-start` }}
              pr={{ base: `8px`, md: `0px` }}
            >
              {celebrityHolders.map((member: TeamMemberProps) => {
                const image = getImage(member.image!.localFile);
                return (
                  <TeamMemberCard
                    profileImage={image}
                    firstName={member.firstName || ``}
                    lastName={member.lastName}
                    isCofounder={member.isCofounder || false}
                    jobTitle={member.jobTitle}
                    favoriteGame={member.favoriteGame || ``}
                    twitter={member.twitter || ``}
                    linkedin={member.linkedin || ``}
                    primaryColor="#00FFC8"
                    secondaryColor="#BDFFF1"
                    bgGradient="linear-gradient(308.45deg, rgba(0, 255, 200, 0.4) -94.97%, rgba(0, 255, 200, 0) 70.06%)"
                    detailsWrapperProps={{
                      maxW: `248px`,
                      minH: `140px`,
                      style: { marginTop: `-2px` },
                    }}
                  />
                );
              })}
            </HStack>
          </VStack>
          <VStack align="flex-start" w="full" id="investors">
            <SectionHeading
              header="investors"
              headerColor="#00FFC8"
              wrapperProps={{ color: `#00FFC8` }}
            />
            <Investors />
          </VStack>
        </VStack>
      </VStack>
    </Layout>
  );
};

export default Team;
