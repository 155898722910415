export const getPseudoBorderBottomProps = (
  primaryColor: string,
  secondaryColor: string,
): any => ({
  textDecor: `none`,
  position: `relative`,
  _after: {
    content: `''`,
    position: `absolute`,
    width: `100%`,
    borderBottom: `0.5px solid ${secondaryColor}`,
    top: `calc(100% - 5px)`,
    left: 0,
    right: 0,
  },
  _hover: {
    color: primaryColor,
    textDecor: `none`,
    __after: {
      borderColor: primaryColor,
    },
  },
});
